import React from 'react';
import './styles/App.css';
import './styles/photo-gallery.css';
import NavBar from './components/NavBar';

// Page Imports
import Contact from './pages/Contact';
import Experience from './pages/Experience';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PhotoGallery from './pages/PhotoGallery';
import Travels from './pages/Travels';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

export const App = () => {
    return (
        <div className="App">
            <Router>
                <NavBar/>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/experience">
                        <Experience/>
                    </Route>
                    <Route exact path="/photos">
                        <PhotoGallery/>
                    </Route>
                    <Route exact path="/contact">
                        <Contact/>
                    </Route>
                    <Route path="/travels">
                        <Travels/>
                    </Route>
                    <Route component={NotFound}/>
                </Switch>
            </Router>
        </div>
    );
}