import React from 'react';
import "../styles/travel.css"

import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";


export default ({currentRoute, setCurrentRoute}) => {
  const decodedRoute = decodeURI(currentRoute)
  const pathnames = decodedRoute.split('/').filter((x) => x);
  return (
    <AppBar position="relative" color='inherit'>
      <div className="travel-navbar-container">
      <Container>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            return last ? (
              <Link to={to} onClick={()=>{setCurrentRoute(to)}}>{value.charAt(0).toUpperCase() + value.slice(1)}</Link>
              ) : (
              <Link to={to} onClick={()=>{setCurrentRoute(to)}}>{value.charAt(0).toUpperCase() + value.slice(1)}</Link>
            );
          })}
        </Breadcrumbs>
      </Container>
      </div>
    </AppBar>
  )
}