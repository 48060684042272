import React from 'react';

import avatarNotFound from '../assets/images/avatar-not-found.png'

export default () => {
    return(
        <div>
            <h1>
                Uh oh ..... where you trying to go?
            </h1>
            <img src={avatarNotFound}/>
        </div>
    )
}