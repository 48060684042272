import React from "react"
import Typewriter from 'typewriter-effect';

import CareerTimeline from "../components/CareerTimeline";


import "../styles/home.css"


export default () => {


    return (
        <div className="home" id="home">
            <div className='home-section-one'>
                <div className='home-section-one-overlay'>
                    <div className='home-section-one-typewriter'>
                        <h3>I like to&nbsp;</h3>
                        
                        <h3>
                            <Typewriter
                                options={{
                                    strings: [
                                        'write code',
                                        'play tennis',
                                        'take pictures',
                                        'go on roadtrips',
                                        'look at mountains',
                                        'drink boba'
                                    ],
                                    autoStart: true,
                                    loop: true
                                }}
                            />
                        </h3>
                    </div>
                </div>
            </div>
            <div className="home-section-two">
                <CareerTimeline/>
            </div>
        </div>
    )
}