import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { getRandomStockImage } from '../pages/PhotoGallery';

export default ({title, setCurrentRoute}) => {

  return (
    <Card>
      <CardActionArea
        component={Link}
        to={"travels/"+title}
        onClick={()=>{setCurrentRoute("travels/"+title)}}
      >
        <CardMedia
          component="img"
          height="140"
          width="240"
          className={'travel-card-media'}
          image={getRandomStockImage()}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
            across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}