import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

export default withScriptjs(withGoogleMap((props) => {
  return (
      <GoogleMap
        defaultZoom={4}
        defaultCenter={{ lat: 40.013, lng: -96.590 }}
      >
        {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
      </GoogleMap>
    )
}))