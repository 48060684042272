import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import "../styles/navbar.css";
import {Link} from "react-router-dom";
import CodeIcon from '@material-ui/icons/Code';
import EmailIcon from '@material-ui/icons/Email';
import ExploreIcon from '@material-ui/icons/Explore';
import HomeIcon from '@material-ui/icons/Home';
import MonochromePhotosIcon from '@material-ui/icons/MonochromePhotos';

export default () => {

    return (
        <AppBar position='relative'>
            <div className="navbar">
                <div className="navbar-title">
                    <Link to="/">
                        <h1>Chris D Truong</h1>
                    </Link>
                </div>
                <div className="navbar-options">
                    <Link to="/"><HomeIcon fontSize="large"/></Link>
                    <Link to="/travels"><ExploreIcon fontSize="large"/></Link>
                    <Link to="/photos"><MonochromePhotosIcon fontSize="large"/></Link>
                    <Link to="/experience"><CodeIcon fontSize="large"/></Link>
                    <Link to="/contact"><EmailIcon fontSize="large"/></Link>
                </div>
            </div>
        </AppBar>
    )
}