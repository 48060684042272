import React from 'react';

import Container from '@material-ui/core/Container';
import CloudIcon from '@material-ui/icons/Cloud';
import SchoolIcon from '@material-ui/icons/School';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default () => {

    return (
        <Container>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#282c34', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '14px solid  #282c34' }}
                    date={<h2> July 2023 - Present</h2>}
                    iconStyle={{ background: '#FF9900', color: '#fff' }}
                    icon={<CloudIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">Senior Systems Development Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <a  href="https://aws.amazon.com/" target="_blank" className="aws-text">
                            Amazon Web Services - Serverless
                        </a>
                    </h4>
                    <p>
                        Building automation for operations, building services in Amazon Dedicated Clouds, and leading team development
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#282c34', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '14px solid  #282c34' }}
                    date={<h2> Oct 2020 - July 2023</h2>}
                    iconStyle={{ background: '#FF9900', color: '#fff' }}
                    icon={<CloudIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">Systems Development Engineer II</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <a  href="https://aws.amazon.com/" target="_blank" className="aws-text">
                            Amazon Web Services - Serverless
                        </a>
                    </h4>
                    <p>
                        Building automation for operations, building services in Amazon Dedicated Clouds, and leading team development
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#282c34', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '14px solid  #282c34' }}
                    date={<h2>Oct 2019 - Oct 2020</h2>}
                    iconStyle={{ background: '#FF9900', color: '#fff' }}
                    icon={<CloudIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">Systems Development Engineer I</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <a  href="https://aws.amazon.com/" target="_blank" className="aws-text">
                            Amazon Web Services - Serverless
                        </a>
                    </h4>
                    <p>
                        Standardized team development standards. Built an operational dashboard for Serverless services in Amazon Dedicated Clouds.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#282c34', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '14px solid  #282c34' }}
                    date={<h2>July 2019 - Oct 2020</h2>}
                    iconStyle={{ background: '#FF9900', color: '#fff' }}
                    icon={<CloudIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">Systems Analyst</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <a  href="https://aws.amazon.com/" target="_blank" className="aws-text">
                            Amazon Web Services - Region Services
                        </a>
                    </h4>
                    <p>
                        Rotated with an ADC DevOps team in the Serverless Organization.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#522D80', color: '#fff' }}
                    date={<h2>Aug 2015 - May 2019</h2>}
                    iconStyle={{ background: '#522D80', color: '#fff' }}
                    icon={<SchoolIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">B.S. Computer Information Systems</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <a href="http://www.clemson.edu/" target="_blank" className="clemson-text">
                            Clemson University
                        </a>
                    </h4>
                    <p>
                        Building a good work-ethic and finding motivation
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </Container>
    )
}