import React, { useState } from 'react';
import "../styles/travel.css"

import { Switch, Route } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import DestinationCard from '../components/DestinationCard';
import GoogleMaps from '../components/GoogleMaps';
import NotFound from './NotFound';
import PhotoGallery from './PhotoGallery';
import TravelNavBar from '../components/TravelNavBar';


const destinations = [
  {
    name: 'Glacier National Park'
  },
  {
    name: 'Seattle'
  },
  {
    name: 'Blue Ridge Parkway'
  }
]

const ListOfPlaces = ({setCurrentRoute}) => {
  const DestinationCards = destinations.map( destination => {
    return (
      <Grid item xs={3}>
        <DestinationCard
          title={destination.name}
          setCurrentRoute={setCurrentRoute}
        />
      </Grid>
    )
  })
  return (
    <Grid container spacing={3}>
        {DestinationCards}
    </Grid>
  )
}


export default () => {
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname)

  let isMarkerShown = false;
  const googleMapsApiKey = "AIzaSyCEPRn_GwjMHeFDlb7wAhRv8FWo-DUxLKE";
  const googleMapsURL = "https://maps.googleapis.com/maps/api/js?key="+ googleMapsApiKey +"&v=3.exp&libraries=geometry,drawing,places"

  return(
    <div className='travel-page-root'>
        <div className="google-maps-container">
            <GoogleMaps
                isMarkerShown={isMarkerShown}
                googleMapURL={googleMapsURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
        <TravelNavBar
          currentRoute={currentRoute}
          setCurrentRoute={setCurrentRoute}
        />
        <div className='travel-content-container'>
          <Container>
              <Switch>
                <Route exact path='/travels'>
                  <ListOfPlaces 
                    setCurrentRoute={setCurrentRoute}
                  />
                </Route>
                <Route path={'/travels/:destinationName'}>
                  <PhotoGallery />
                </Route>
                <Route component={NotFound}/>
              </Switch>
          </Container>
        </div>
    </div>
  )
}